<template>
  <v-container class="pa-0 app-fill-height mobile-container">
    <v-container tag="section" class="pa-0 mb-10 mobile-container">
      <v-responsive max-width="800px" class="mx-auto">
          <v-card class="page-box-wrapper elevation-3">
            <div class="page-box-content">
              <h2 class="h2-green">Руководство по питанию и оздоровлению</h2>
              <div class="img-top-box">
                <div class="text-center">
                  <img
                    src="/img/marathon/eating-marathon.jpg"
                    alt=""
                    class="eating-marathon"
                  />
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h3 class="h3-green">
                    Твоё самочувствие и внешний вид зависят от нескольких
                    ключевых факторов:
                  </h3>
                  <ul class="ul-check">
                    <li>🍏 Питание,</li>
                    <li>💧 Водный баланс,</li>
                    <li>😴 Режим сна,</li>
                    <li>🤸‍♀️ Физическая активность,</li>
                    <li>🤯 Уровень стресса.</li>
                  </ul>
                  <p class="mt-3">
                    Этот гид поможет постепенно сменить привычки, наладить режим
                    и оздоровить организм. Когда тело работает слаженно, оно
                    отвечает благодарностью: прилив энергии, снижение веса,
                    улучшение самочувствия и внешнего вида.
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/e7qb5mrtvk?web_component=true&seo=true" title="Введение Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="start">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Перед тем, как начать движение к здоровью и молодости:
                  </h2>
                  <ul class="ul-check mt-5">
                    <li>1️⃣ Взвеситься – утром, натощак, с минимумом одежды.</li>
                    <li>
                      2️⃣ Замерить объем талии и бедер – ведь именно уменьшение
                      объёмов важнее, чем цифра на весах.
                    </li>
                    <li>
                      3️⃣ Разденься и посмотри на себя в зеркало. Только не спеши
                      расстраиваться – следуя этому руководству, ты сможешь
                      выглядеть так, что захочется любоваться собой! 🔥
                    </li>
                  </ul>
                  <p class="mt-5">
                    <strong>💡 Норма для здорового организма:</strong>
                  </p>
                  <ul class="ul-check">
                    <li>🙎‍♀️ Женщины: талия до 80 см</li>
                    <li>🙎‍♂️ Мужчины: талия до 90–95 см</li>
                  </ul>
                  <p class="mt-5">
                    👉 Всё, что выше – сигнал, что пора действовать!
                  </p>
                  <p class="mt-5">
                    ✨ Ну что, готовы начать путь к энергичному и здоровому
                    телу? Тогда вперёд! 😊
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="food">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Шаг 1️⃣: Исключить или минимизировать вредные продукты ❌
                  </h2>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="exclude">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">🔴 Что исключить?</h2>
                  <ul class="ul-check">
                    <li>
                      ❌ Лишний сахар (конфеты, сладости, сахар в кофе/чае).
                    </li>
                    <li>
                      ❌ Мучное (хлеб, выпечка, даже цельнозерновой магазинный
                      хлеб).
                    </li>
                    <li>❌ Картофель (кроме печёного в кожуре, но редко).</li>
                    <li>
                      ❌ Белый рис, булгур, кускус, макароны, пельмени,
                      фаст-фуд.
                    </li>
                    <li>❌ Продукты быстрого приготовления (лапша, пюре).</li>
                    <li>
                      ❌ Коровье молоко и большинство молочных продуктов –
                      заменяем на альтернативы.
                    </li>
                    <li>
                      ❌ Фрукты (ограничиваем до 1–2 в день, исключаем бананы и
                      виноград на время похудения).
                    </li>
                  </ul>
                  <h3 class="mt-5">Почему это важно?</h3>
                  <p class="mt-3">
                    🚀 Исключение сахара и рафинированных углеводов снижает риск
                    инсулинорезистентности, ожирения и воспалительных процессов.
                  </p>
                  <p class="mt-3">
                    🚀 Обработанные продукты с трансжирами повышают уровень
                    «плохого» холестерина (LDL) и увеличивают риск
                    сердечно-сосудистых заболеваний.
                  </p>
                  <h3 class="mt-5">Это даёт тебе:</h3>
                  <ul class="ul-check">
                    <li>
                      Стабилизацию уровня сахара в крови – меньше скачков
                      энергии.
                    </li>
                    <li>Больше энергии без резких спадов.</li>
                    <li>
                      Улучшение общего самочувствия и снижение тяги к сладкому.
                    </li>
                  </ul>
                  <h3 class="mt-5">Совет:</h3>
                  <ul class="ul-check">
                    <li>
                      ✅ Чем заменить сладости? Финики, ягоды, чёрный шоколад
                      85%+.
                    </li>
                    <li>
                      ✅ Альтернатива мучному? Домашний хлеб из ржаной,
                      гречневой или овсяной муки.
                    </li>
                    <li>
                      ✅ Контроль сахара в крови: Принимай Алоэ Вера гель – он
                      помогает снижать уровень глюкозы и уменьшает
                      воспалительные процессы.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="preferences">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Шаг 2️⃣: Увеличить количество зелени и овощей 🥦
                  </h2>
                  <h3 class="mt-5">🥗 Что добавить в рацион:</h3>
                  <ul class="ul-check">
                    <li>✅ Брокколи, цветная капуста, стручковая фасоль.</li>
                    <li>
                      ✅ Огурцы, зелень, шпинат, руккола, болгарский перец.
                    </li>
                    <li>✅ Свёкла, морковь, кабачки, баклажаны, лук.</li>
                  </ul>
                  <h3 class="mt-5">Почему это важно?</h3>
                  <p class="mt-3">
                    🚀 Овощи богаты клетчаткой, которая поддерживает
                    пищеварение, снижает воспаление и питает полезные бактерии в
                    кишечнике.
                  </p>
                  <p class="mt-3">
                    🚀 Здоровый микробиом = сильный иммунитет и нормальный обмен
                    веществ.
                  </p>
                  <h3 class="mt-5">Это даёт тебе:</h3>
                  <ul class="ul-check">
                    <li>Улучшение пищеварения – кишечник работает как часы.</li>
                    <li>
                      Крепкий иммунитет – 80% защитных функций организма связаны
                      с кишечником.
                    </li>
                    <li>Снижение воспалений и улучшение состояния кожи.</li>
                  </ul>
                  <h3 class="mt-5">Совет:</h3>
                  <ul class="ul-check">
                    <li>
                      ✅ Старайся разнообразить овощи и зелень – включай 5+
                      различных овощей в салаты.
                    </li>
                    <li>
                      ✅ Добавляй в салаты полезные специи: куркуму, орегано,
                      розмарин – они обладают антиоксидантными свойствами.
                    </li>
                    <li>
                      ✅ Добавляй в рацион клетчатку LR FiberBoost – она
                      улучшает работу кишечника.
                    </li>
                    <li>
                      ✅ Принимай симбиотик PRO 12 – для восстановления
                      микрофлоры кишечника.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="preferences">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Шаг 3️⃣: Увеличь белок и полезные жиры
                  </h2>
                  <p class="mt-3">
                    📌 Белки – строительный материал организма, без него не
                    обновляются ткани, не растут мышцы и не вырабатываются
                    гормоны.
                  </p>
                  <p class="mt-3">
                    📌 Жиры – необходимы для энергии, работы мозга, нервной
                    системы и здоровья гормонов.
                  </p>
                  <p class="mt-3"><strong>✅ Белок нужен для:</strong></p>
                  <ul class="ul-check">
                    <li>Восстановления мышц и клеток.</li>
                    <li>Поддержки иммунитета (антитела – это белки!).</li>
                    <li>
                      Выработки ферментов и гормонов (особенно важен для
                      тестостерона!).
                    </li>
                  </ul>
                  <p class="mt-3">
                    <strong>✅ Полезные жиры нужны для:</strong>
                  </p>
                  <ul class="ul-check">
                    <li>
                      Производства гормонов, включая тестостерон и эстроген.
                    </li>
                    <li>Питания мозга и нервной системы.</li>
                    <li>Поддержки кожи, суставов и сосудов.</li>
                  </ul>
                  <h3 class="mt-5">Что делать?</h3>
                  <p class="mt-3">
                    📌 Добавь в рацион больше качественного белка:
                  </p>
                  <ul class="ul-check">
                    <li>
                      ✔ Рыба, индейка, курица, говядина, яйца, греческий йогурт.
                    </li>
                    <li>✔ Растительные источники: нут, киноа, тофу.</li>
                    <li>
                      ✔ Протеиновый коктейль LR Protein Power – удобный источник
                      5 видов белка, включая казеин для ночного питания мышц.
                    </li>
                  </ul>
                  <p class="mt-3">📌 Добавь полезные жиры:</p>
                  <ul class="ul-check">
                    <li>
                      ✔ Авокадо, орехи, семечки, кокосовое и оливковое масло.
                    </li>
                    <li>
                      ✔ Омега-3 LR Super Omega – ключевой жир для мозга, сердца
                      и сосудов.
                    </li>
                  </ul>
                  <h3 class="mt-5">Совет:</h3>
                  <p class="mt-3">
                    🔥 Старайся сочетать белки и жиры в каждом приёме пищи – это
                    обеспечит энергию, сытость и баланс гормонов!
                  </p>
                  <p class="mt-3">
                    Такой баланс ускоряет метаболизм, поддерживает мышцы и
                    помогает контролировать вес!
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="preferences">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Шаг 4️⃣: Обеспечь поступление микро- и макронутриентов
                  </h2>
                  <p class="mt-3">
                    Современный ритм жизни, качество продуктов и истощённые
                    почвы делают невозможным получение всех необходимых
                    витаминов и минералов только из пищи. Даже при идеальном
                    рационе сложно полностью покрыть суточную потребность
                    организма в микро- и макроэлементах.
                  </p>
                  <ul class="ul-check">
                    <li>
                      ✅ Овощи и фрукты содержат меньше витаминов, чем 50 лет
                      назад – из-за агрессивного земледелия.
                    </li>
                    <li>
                      ✅ Даже при сбалансированном питании человек часто
                      испытывает дефицит магния, железа, витамина D, йода и
                      омега-3.
                    </li>
                    <li>
                      ✅ Загрязнённая экология, стрессы и интенсивный ритм жизни
                      повышают потребность организма в нутриентах.
                    </li>
                  </ul>
                  <h3 class="mt-5">Что делать?</h3>
                  <p class="mt-3">
                    📌 Дополняй рацион проверенными нутрицевтиками LR – они
                    помогут восполнить дефициты и поддержать здоровье.
                  </p>
                  <ul class="ul-check">
                    <li>
                      ✔ VitaAktiv – источник важных витаминов, которые
                      поддерживают энергию, иммунитет и работу нервной системы.
                    </li>
                    <li>
                      ✔ ProBalance – баланс минералов, необходимых для обменных
                      процессов, здоровья костей и щелочного баланса.
                    </li>
                    <li>
                      ✔ Super Omega 3 – для здоровья сердца, мозга, сосудов и
                      гормонального баланса.
                    </li>
                    <li>
                      ✔ Гель Алоэ Вера – улучшает усвоение нутриентов,
                      поддерживает ЖКТ и иммунную систему.
                    </li>
                    <li>
                      ✔ Pro 12 – восстанавливает микробиом, что напрямую влияет
                      на пищеварение, иммунитет и настроение.
                    </li>
                  </ul>
                  <h3 class="mt-5">Совет:</h3>
                  <p class="mt-3">
                    🔥 Сделай нутрицевтики LR частью своего ежедневного рациона
                    – это залог здоровья, энергии и долголетия!
                  </p>
                  <p class="mt-3">📌 Используй правильную стратегию:</p>
                  <ul class="ul-check">
                    <li>
                      ✅ Утром – VitaAktiv и Алоэ Вера для усвоения витаминов.
                    </li>
                    <li>
                      ✅ В течение дня – Омега-3 и ProBalance для поддержки
                      организма.
                    </li>
                    <li>
                      ✅ Перед сном – Pro12 для восстановления микробиома.
                    </li>
                  </ul>
                  <p class="mt-3">
                    💡 Так ты обеспечишь организм всем необходимым и
                    почувствуешь разницу уже в первые недели! 🚀
                  </p>
                  <h3 class="mt-5">
                    💡 Итог: питание – это не диета, а осознанный выбор
                  </h3>
                  <p class="mt-3">
                    Когда ты начинаешь поддерживать свой организм правильными
                    продуктами, он отвечает здоровьем, лёгкостью и энергией.
                  </p>
                  <p class="mt-3">
                    🔥 Начни с первого шага – и ты увидишь, как изменится твоё
                    самочувствие!
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Примерный рацион – сбалансированное питание на день
                  </h2>
                  <p class="mt-3">
                    Этот рацион учитывает принципы здорового питания, циркадные
                    ритмы, баланс нутриентов и рекомендации по приёму продуктов
                    LR для максимального эффекта.
                  </p>
                  <h3 class="h3-green mt-5" id="breakfast">
                    Как начинать день? 🌅
                  </h3>
                  <p class="mt-3">
                    🔹 Сразу после пробуждения – стакан горячей воды. Это
                    запускает пищеварение, улучшает обмен веществ и помогает
                    организму вывести токсины.
                  </p>
                  <p class="mt-3">
                    🔹 Перед завтраком – гель Алоэ Вера + VitaAktiv
                  </p>
                  <ul class="ul-check">
                    <li>
                      ✅ Алоэ Вера гель – увеличивает биодоступность витаминов,
                      поддерживает ЖКТ и укрепляет иммунитет.
                    </li>
                    <li>
                      ✅ VitaAktiv – обеспечивает организм необходимыми
                      витаминами на день.
                    </li>
                  </ul>
                  <h3 class="h3-green mt-5" id="breakfast">
                    Завтрак (7:00 – 9:00) ☀️
                  </h3>
                  <p class="mt-3">
                    📌 Оптимально завтракать через 30-60 минут после
                    пробуждения.
                  </p>
                  <h3 class="mt-3">Что включить в завтрак:</h3>
                  <p class="mt-3">
                    <strong
                      >✔ Сложные углеводы – энергия на долгий день:</strong
                    >
                  </p>
                  <ul class="ul-check">
                    <li>
                      ✔️ Овсянка (НЕ быстрого приготовления) – можно варить или
                      заливать тёплым овсяным «немолоком».
                    </li>
                    <li>
                      ✔️ Гречка – можно залить кипятком с вечера, утром добавить
                      растительное молоко.
                    </li>
                    <li>✔️ Киноа – источник белка и минералов.</li>
                  </ul>
                  <p class="mt-3">
                    <strong
                      >✔ Белок – для восстановления клеток и работы
                      гормонов:</strong
                    >
                  </p>
                  <ul class="ul-check">
                    <li>✔️ Яйца (омлет, варёные, пашот, глазунья).</li>
                    <li>✔️ Сырники из творога 5-9% или творожная запеканка.</li>
                    <li>
                      ✔️ Греческий йогурт с орехами, семечками и ложечкой мёда.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <strong
                      >✔ Полезные жиры – для работы мозга и стабильного сахара в
                      крови:</strong
                    >
                  </p>
                  <ul class="ul-check">
                    <li>✔️ Орехи (миндаль, грецкие, фундук) – 5-6 шт.</li>
                    <li>
                      ✔️ Семечки (льняные, подсолнечные, тыквенные) – 1 ст.
                      ложка.
                    </li>
                    <li>✔️ Кокосовое или овсяное молоко в кофе.</li>
                  </ul>
                  <h3 class="mt-5">Совет:</h3>
                  <ul class="ul-check">
                    <li>
                      ✅ Добавь в утренний рацион чай LR Fasting – поддерживает
                      обмен веществ, снижает аппетит, ускоряет жиросжигание.
                    </li>
                    <li>
                      ✅ Принимай гриб Рейши 1 раз в день после или во время
                      завтрака – он укрепляет иммунитет, поддерживает печень и
                      нервную систему.
                    </li>
                  </ul>

                  <h3 class="h3-green mt-5" id="lunch">
                    Обед (13:00 – 14:30) 🍛
                  </h3>
                  <p class="mt-3">
                    Обед – основной приём пищи, обеспечивающий организм энергией
                    и необходимыми нутриентами.
                  </p>
                  <h3 class="mt-5">Что включить в обед:</h3>
                  <p class="mt-3">
                    <strong>✔ Овощи – клетчатка и витамины:</strong>
                  </p>
                  <ul class="ul-check">
                    <li>
                      ✔️ Салаты из свежих овощей с зеленью (5+ ингредиентов:
                      огурец, помидор, перец, шпинат, укроп, петрушка).
                    </li>
                    <li>
                      ✔️ Тушёные овощи (брокколи, цветная капуста, морковь,
                      кабачки, баклажаны).
                    </li>
                  </ul>
                  <p class="mt-3">
                    <strong>✔ Белок – для мышц и обмена веществ:</strong>
                  </p>
                  <ul class="ul-check">
                    <li>✔️ Рыба (лосось, треска, скумбрия, тунец).</li>
                    <li>
                      ✔️ Куриные бёдра или индейка (запечённые или на пару).
                    </li>
                    <li>✔️ Говядина – источник железа и аминокислот.</li>
                  </ul>
                  <p class="mt-3"><strong>✔ Полезные жиры:</strong></p>
                  <ul class="ul-check">
                    <li>✔️ Авокадо – 1/2 плода в салат или просто ложкой.</li>
                    <li>
                      ✔️ Оливковое масло первого отжима – 1-2 ложки на овощи.
                    </li>
                    <li>✔️ Орехи или семечки – небольшая горсть.</li>
                  </ul>
                  <h3 class="mt-5">Совет:</h3>
                  <ul class="ul-check">
                    <li>
                      ✅ Перед обедом выпивай Алоэ Вера гель – улучшает
                      пищеварение и усвоение питательных веществ.
                    </li>
                    <li>
                      ✅ Принимай 1 капсулу LR Super Omega во время еды –
                      поддержка сердечно-сосудистой системы, мозга и суставов.
                    </li>
                    <li>
                      ✅ Через 1,5 часа после обеда – 4 таблетки ProBalance с
                      водой – восстанавливает кислотно-щелочной баланс и
                      улучшает обмен веществ.
                    </li>
                  </ul>
                  <h3 class="h3-green mt-5" id="snuck">
                    Перекус (16:00 – 17:00) 🍏
                  </h3>
                  <p class="mt-3">
                    Помогает избежать вечернего переедания и поддерживать
                    стабильный уровень сахара в крови.
                  </p>
                  <h3 class="mt-5">Что включить в перекус:</h3>
                  <p class="mt-3">
                    <strong>✔ Фрукты (не больше 2 шт. в день):</strong>
                  </p>
                  <ul class="ul-check">
                    <li>✔️ Яблоко, груша, ягоды, сливы.</li>
                    <li>✔️ На период похудения исключаем бананы и виноград.</li>
                  </ul>
                  <p class="mt-3">
                    <strong>✔ Орехи или семечки – небольшая порция.</strong>
                  </p>
                  <p class="mt-3">
                    <strong>✔ Горький шоколад 85%+ – 10-15 г.</strong>
                  </p>
                  <p class="mt-3">
                    <strong>✔ Травяной чай или зелёный чай без сахара.</strong>
                  </p>

                  <h3 class="h3-green mt-5" id="dinner">
                    Ужин (18:00 - 19:00) 🌙
                  </h3>
                  <p class="mt-3">
                    По циркадным биоритмам организм после 19:00 переходит в
                    стадию восстановления, поэтому важно ужинать не позже этого
                    времени.
                  </p>
                  <h3 class="mt-5">Что включить в ужин:</h3>
                  <p class="mt-3"><strong>✔ Овощи:</strong></p>
                  <ul class="ul-check">
                    <li>
                      ✔️ Тушёные овощи (брокколи, цветная капуста, кабачки).
                    </li>
                    <li>✔️ Лёгкие салаты с зеленью и оливковым маслом.</li>
                  </ul>
                  <p class="mt-3">
                    <strong>✔ Белок (лёгкий, нежирный):</strong>
                  </p>
                  <ul class="ul-check">
                    <li>
                      ✔️ Рыба – отличный вариант на ужин (лосось, судак, хек).
                    </li>
                    <li>
                      ✔️ Индейка, курица – легко усваиваются, не перегружают
                      ЖКТ.
                    </li>
                    <li>
                      ✔️ Творог 5% в запечённом виде – источник белка перед
                      сном.
                    </li>
                  </ul>
                  <p class="mt-3"><strong>✔ Полезные жиры:</strong></p>
                  <ul class="ul-check">
                    <li>✔️ Авокадо, оливковое масло – помогают насыщению.</li>
                    <li>
                      ✔️ Орехи или семена льна – поддержка гормонального
                      баланса.
                    </li>
                  </ul>
                  <h3 class="mt-5">Совет:</h3>
                  <ul class="ul-check">
                    <li>
                      ✅ Перед ужином выпивай Алоэ Вера гель – улучшает
                      пищеварение, поддерживает ЖКТ и баланс сахара в крови.
                    </li>
                    <li>
                      ✅ Принимай 1 капсулу LR Super Omega во время еды – для
                      защиты сердца и суставов.
                    </li>
                    <li>
                      ✅ Через 1,5 часа после ужина – 4 таблетки ProBalance с
                      водой – улучшает pH-баланс и способствует восстановлению
                      организма.
                    </li>
                    <li>
                      ✅ Перед сном – симбиотик PRO 12 + FiberBoost – улучшает
                      работу кишечника, снижает воспаление и укрепляет
                      иммунитет.
                    </li>
                  </ul>
                  <h3 class="mt-5">
                    💡 Итог: твой рацион – это энергия, лёгкость и здоровье!
                  </h3>
                  <p class="mt-3">
                    🔥 Сбалансированное питание + LR-продукция дают стабильный
                    вес, хорошее самочувствие и отличное настроение!
                  </p>
                  <p class="mt-3">
                    ✨ Выбирай качественные продукты – и организм ответит
                    благодарностью!
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="protein">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Дополнительная белковая подпитка</h2>
                  <p class="mt-3">
                    Белок – ключевой нутриент для восстановления организма,
                    сохранения мышечной массы и нормального обмена веществ.
                    Однако большинство людей испытывают его дефицит, особенно в
                    вечернее время.
                  </p>
                  <p class="mt-3">
                    Поэтому крайне рекомендуется добавить дополнительную порцию
                    белка вечером (ориентировочно в 21:00 - 21:30).
                  </p>
                  <h3 class="mt-3">Почему это важно?</h3>
                  <p class="mt-3">
                    ✅ Белок способствует регенерации клеток и восстановлению
                    тканей во время сна.
                  </p>
                  <p class="mt-3">
                    ✅ Помогает сохранить и наращивать мышечную массу, особенно
                    во время снижения веса.
                  </p>
                  <p class="mt-3">
                    ✅ Обеспечивает длительное насыщение, предотвращая вечерний
                    и ночной голод.
                  </p>
                  <p class="mt-3">
                    ✅ Стабилизирует уровень сахара в крови, уменьшая колебания
                    инсулина.
                  </p>
                  <h3 class="mt-3">Какой белок лучше выбрать?</h3>
                  <p class="mt-3">✔ Оптимальный вариант – LR Protein Power</p>
                  <p class="mt-3">
                    Этот протеиновый коктейль содержит 5 видов белка, включая
                    казеин, который обеспечивает стабильное поступление
                    аминокислот в организм в течение всей ночи.
                  </p>
                  <p class="mt-3">✔ Как принимать?</p>
                  <ul class="ul-check">
                    <li>
                      Размешай 1 порцию LR Protein Power в воде или растительном
                      молоке.
                    </li>
                    <li>
                      Можно добавить клетчатку LR FiberBoost для улучшения
                      пищеварения и насыщения.
                    </li>
                  </ul>
                  <p class="mt-5">
                    <strong>✔ Можно ли есть яйца перед сном?</strong>
                  </p>
                  <p class="mt-3">
                    ❌ Лучше не стоит. Варёные или жареные яйца нагружают
                    поджелудочную железу и ЖКТ, что может вызвать дискомфорт и
                    ухудшение пищеварения.
                  </p>
                  <p class="mt-5">
                    <strong>✔ А как насчёт творога или кефира?</strong>
                  </p>
                  <p class="mt-3">
                    ❌ Исключаем. Казеин из молочных продуктов усваивается хуже,
                    чем из изолированного белка, а лактоза может вызывать
                    воспаления и задержку жидкости.
                  </p>
                  <h3 class="mt-5">Забота о микробиоме перед сном</h3>
                  <p class="mt-3">
                    Не менее важно поддерживать микробиом кишечника, так как
                    здоровая микрофлора = крепкий иммунитет и хорошее
                    пищеварение.
                  </p>
                  <p class="mt-3">✔ Перед сном – приём пробиотика LR Pro12</p>
                  <ul class="ul-check">
                    <li>
                      Способствует улучшению пищеварения, восстановлению
                      кишечной флоры и укреплению иммунитета.
                    </li>
                    <li>
                      Работает на клеточном уровне, помогая микробиоте
                      заселяться правильно.
                    </li>
                  </ul>
                  <p class="mt-3">✔ Добавь FiberBoost</p>
                  <ul class="ul-check">
                    <li>
                      Поддерживает рост полезных бактерий и улучшает работу
                      кишечника.
                    </li>
                    <li>Создаёт комфортные условия для переваривания пищи.</li>
                  </ul>
                  <h3 class="mt-5">Итог: что делать вечером?</h3>
                  <p class="mt-3">
                    ✅ В 21:00 - 21:30 – выпить порцию LR Protein Power – его
                    казеин обеспечит организм аминокислотами в течение всей
                    ночи.
                  </p>
                  <p class="mt-3">
                    ✅ Перед сном – пробиотик LR Pro12 + FiberBoost – поддержка
                    кишечника и иммунитета.
                  </p>
                  <p class="mt-3">
                    ✅ Избегать тяжёлой пищи (яиц, творога, кефира, сложных
                    жиров).
                  </p>
                  <p class="mt-3">
                    🔥 Такой подход ускоряет восстановление организма,
                    поддерживает мышцы и нормализует метаболизм во сне!
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="water">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Вода – главный элемент здоровья 💧
                  </h2>
                  <p class="mt-3">
                    Вода – это основа жизни и один из важнейших элементов,
                    влияющих на общее самочувствие, обмен веществ и уровень
                    энергии.
                  </p>
                  <h3 class="mt-3">Почему это важно?</h3>
                  <p class="mt-3">
                    ✅ Обеспечивает гидробаланс – вода участвует в
                    терморегуляции, транспортировке питательных веществ и
                    выведении продуктов обмена.
                  </p>
                  <p class="mt-3">
                    ✅ Предотвращает обезвоживание – недостаток жидкости может
                    вызвать слабость, головную боль и снижение концентрации.
                  </p>
                  <p class="mt-3">
                    ✅ Ускоряет обмен веществ – достаточное потребление воды
                    улучшает работу ЖКТ и помогает в расщеплении жиров.
                  </p>
                  <p class="mt-3">
                    ✅ Поддерживает суставы, кожу и работу почек – без воды
                    организм не способен нормально функционировать.
                  </p>
                  <h3 class="mt-3">Сколько воды нужно пить?</h3>
                  <p class="mt-3">
                    Рекомендация пить 1,5–2 литра воды в сутки – усреднённая
                    норма, которая не учитывает индивидуальные потребности.
                  </p>
                  <p class="mt-3">Научно обоснованные методы расчёта:</p>
                  <p class="mt-3"><strong>1️⃣ По массе тела:</strong></p>
                  <ul class="ul-check">
                    <li>📌 25–30 мл воды на 1 кг веса.</li>
                    <li>
                      🔹 Человек весом 70 кг должен потреблять 1,75 – 2,1 л воды
                      в день.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <strong>2️⃣ С учётом физической активности:</strong>
                  </p>
                  <ul class="ul-check">
                    <li>
                      📌 30 мл на 1 кг веса + 500 мл на каждый час физической
                      активности.
                    </li>
                    <li>
                      🔹 Человек весом 70 кг, занимающийся спортом 1 час в день,
                      должен выпивать 2,6 литра воды.
                    </li>
                  </ul>
                  <h3 class="mt-3">Последние научные данные: мифы и факты</h3>
                  <p class="mt-5">
                    <strong>💡 Миф:</strong> Нужно пить только воду, чай и кофе
                    не учитываются.
                  </p>
                  <p class="mt-3">
                    <strong>✅ Факт:</strong> В суточную норму жидкости входит
                    не только чистая вода, но и другие напитки (чай, кофе,
                    травяные отвары) и даже продукты с высоким содержанием воды,
                    особенно овощи и фрукты.
                  </p>

                  <p class="mt-5">
                    <strong>💡 Миф:</strong> Чай и кофе обезвоживают организм.
                  </p>
                  <p class="mt-3">
                    <strong>✅ Факт:</strong> Современные исследования
                    показывают, что умеренное употребление чая и кофе (до 3
                    чашек в день) не приводит к значительной потере жидкости.
                    Хотя кофеин обладает мочегонным эффектом, исследования
                    показали, что регулярное употребление кофе вызывает
                    толерантность к этому воздействию. Это означает, что у
                    людей, регулярно пьющих кофе, диуретический эффект
                    снижается, и кофе не оказывает значительного влияния на
                    гидратацию организма.
                  </p>

                  <p class="mt-5">
                    <strong>💡 Миф:</strong> Воду нужно пить только тогда, когда
                    ощущаешь жажду.
                  </p>
                  <p class="mt-3">
                    <strong>✅ Факт:</strong> Ощущение жажды – это уже сигнал
                    лёгкого обезвоживания. Лучше поддерживать регулярный водный
                    баланс в течение дня.
                  </p>
                  <h3 class="mt-5">Какую воду пить?</h3>
                  <h4 class="mt-3">1. Водопроводная вода</h4>
                  <p class="mt-3">
                    В большинстве городов водопроводная вода содержит хлор,
                    ржавчину и другие загрязнения, собранные при
                    транспортировке. Поэтому пить её без дополнительной очистки
                    не рекомендуется.
                  </p>
                  <h4 class="mt-3">2. Кипячёная вода</h4>
                  <p class="mt-3">
                    Кипячение уничтожает некоторые микроорганизмы, но не удаляет
                    тяжёлые металлы и химические примеси. Кроме того, при
                    кипячении вода теряет кислород, становясь "мёртвой", что
                    снижает её пользу для организма.
                  </p>
                  <h4 class="mt-3">3. Бутилированная вода</h4>
                  <p class="mt-3">
                    Бутилированная вода проходит строгий контроль качества и
                    готова к употреблению. Однако это весьма не дешевый вариант.
                  </p>
                  <h4 class="mt-3">
                    4. Фильтрованная вода – оптимальный вариант
                  </h4>
                  <p class="mt-3">
                    Лучший вариант – вода из специальных водоматов, где
                    используются мощные многоступенчатые фильтры и система
                    минерализации. Такая вода:
                  </p>
                  <p class="mt-3">
                    ✅ Проходит качественную очистку от примесей и тяжелых
                    металлов.
                  </p>
                  <p class="mt-3">
                    ✅ Сохраняет баланс минералов, необходимых для организма.
                  </p>
                  <p class="mt-3">
                    ✅ Экономичнее и экологичнее бутилированной воды.
                  </p>
                  <p class="mt-3">
                    Если нет доступа к такой воде, можно использовать домашние
                    системы фильтрации с обратным осмосом и минерализацией.
                  </p>
                  <h3 class="mt-5">Как поддерживать водный баланс?</h3>
                  <p class="mt-3">
                    ✔ Начинай день со стакана горячей воды – помогает
                    «запустить» пищеварение и обмен веществ.
                  </p>
                  <p class="mt-3">
                    ✔ Равномерно распределяй приём воды в течение дня – не
                    выпивай всё сразу.
                  </p>
                  <p class="mt-3">
                    ✔ Добавляй в рацион больше овощей и фруктов – они тоже дают
                    организму необходимую жидкость.
                  </p>
                  <p class="mt-3">
                    ✔ Пей фильтрованную воду – она обеспечивает организм
                    полезными минералами и не содержит примесей.
                  </p>
                  <p class="mt-3">
                    🔥 Выбирай разумный подход к водному балансу – и организм
                    ответит лёгкостью, энергией и здоровьем!
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="physical">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Физическая активность – залог здоровья и энергии
                  </h2>
                  <p class="mt-3">
                    Современный образ жизни – сидячая работа, гаджеты,
                    малоподвижность, передвижение на авто – негативно
                    сказывается на нашем здоровье. Тело создано для движения, и
                    если его лишить активности, ухудшается работа всех систем
                    организма.
                  </p>
                  <p class="mt-3">
                    <strong>«📌 Движение – это жизнь.».</strong>
                  </p>
                  <p class="mt-3">
                    Чем больше мы двигаемся, тем лучше работает сердце, обмен
                    веществ, суставы, мышцы и даже мозг.
                  </p>
                  <h3 class="mt-5">Какую физическую активность выбрать?</h3>
                  <p class="mt-3">
                    💡 Неважно, какой у тебя уровень подготовки – движение
                    должно быть каждый день!
                  </p>
                  <p class="mt-3">
                    ✅ 1. Самая простая и доступная активность – ходьба
                  </p>
                  <ul class="ul-check">
                    <li>
                      Минимум 10 000 шагов в день – это поддерживает здоровье
                      сосудов, суставов, снижает стресс и улучшает настроение.
                    </li>
                    <li>
                      🚶‍♂ Ходьба после еды помогает стабилизировать уровень
                      сахара в крови и ускоряет метаболизм.
                    </li>
                  </ul>
                  <p class="mt-5">
                    ✅ 2. Приседания – универсальное упражнение для всех
                  </p>
                  <p class="mt-3">Почему именно приседания?</p>
                  <ul class="ul-check">
                    <li>
                      🔹 Включают в работу крупные мышцы (ноги, ягодицы, спину,
                      пресс), что требует много энергии – полезно при похудении.
                    </li>
                    <li>
                      🔹 Запускают выработку дофамина – гормона радости и
                      бодрости.
                    </li>
                    <li>
                      🔹 Улучшают циркуляцию крови, поддерживают здоровье
                      сосудов и суставов.
                    </li>
                    <li>
                      🔹 Легко выполнять в любом возрасте и на любом уровне
                      подготовки.
                    </li>
                  </ul>
                  <p class="mt-3">📌 Как делать?</p>
                  <p class="mt-3">✔ Цель – 100 приседаний в день.</p>
                  <p class="mt-3">
                    ✔ Можно начинать с минимума и постепенно увеличивать.
                  </p>
                  <p class="mt-3">
                    ✔ Разбивай на подходы (например, 4 по 25 или 3 по 33).
                  </p>
                  <p class="mt-5">✅ 3. Дополняем программу:</p>
                  <ul class="ul-check">
                    <li>
                      Отжимания – развивают грудные мышцы, руки, плечи, спину.
                    </li>
                    <li>
                      Подтягивания – укрепляют верхний плечевой пояс и спину.
                    </li>
                    <li>
                      Планка – тренирует пресс, мышцы корпуса и улучшает осанку.
                    </li>
                  </ul>
                  <h3 class="mt-5">Почему это важно?</h3>
                  <p class="mt-3">
                    ✅ Снижает уровень стресса – при движении организм
                    вырабатывает эндорфины и серотонин, которые помогают
                    бороться со стрессом и тревожностью.
                  </p>
                  <p class="mt-3">
                    ✅ Разгоняет метаболизм – чем больше двигаемся, тем лучше
                    работает обмен веществ и сжигаются лишние калории.
                  </p>
                  <p class="mt-3">
                    ✅ Защищает сердце и сосуды – регулярная активность снижает
                    риск сердечно-сосудистых заболеваний.
                  </p>
                  <p class="mt-3">
                    ✅ Укрепляет кости и суставы – снижает риск остеопороза и
                    возрастных заболеваний опорно-двигательного аппарата.
                  </p>
                  <p class="mt-3">
                    ✅ Поддерживает молодость и энергичность – активные люди
                    стареют медленнее и чувствуют себя лучше.
                  </p>
                  <h3 class="mt-3">Советы:</h3>
                  <ul class="ul-check">
                    <li>
                      ✔ Начни с малого – даже 10 минут активности в день дадут
                      пользу.
                    </li>
                    <li>
                      ✔ Выбери движение, которое тебе нравится – ходьба, танцы,
                      йога, силовые тренировки – всё работает!
                    </li>
                    <li>
                      ✔ Добавляй упражнения в утреннюю рутину – приседания,
                      планка, отжимания займут не больше 5-10 минут, но зарядят
                      энергией на день.
                    </li>
                    <li>
                      ✔ Слушай своё тело – важно избегать травм и чрезмерных
                      нагрузок.
                    </li>
                  </ul>
                  <p class="mt-3">🔥 Двигайся больше – живи дольше! 🚀</p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="dream">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Сон и восстановление</h2>
                  <p class="mt-3">
                    Сон играет ключевую роль в поддержании здоровья и общего
                    самочувствия. Он влияет на работу мозга, иммунной системы,
                    обмен веществ и многие другие процессы в организме.
                  </p>
                  <p class="mt-5">
                    <strong>Оптимальное время для сна:</strong>
                  </p>
                  <p class="mt-3">
                    Исследования показывают, что отход ко сну между 22:00 и
                    23:00 ассоциируется с наименьшим риском развития
                    сердечно-сосудистых заболеваний. Смещение времени сна на
                    более поздние или ранние часы может нарушать циркадные
                    ритмы, что негативно сказывается на здоровье.
                  </p>
                  <h3 class="mt-3">Советы:</h3>
                  <ul class="ul-check">
                    <li>
                      ✅ Соблюдайте режим: Ложитесь спать и просыпайтесь в одно
                      и то же время, даже в выходные дни.
                    </li>
                    <li>
                      ✅ Создайте комфортные условия: Обеспечьте тишину, темноту
                      и оптимальную температуру в спальне.
                    </li>
                    <li>
                      ✅ Ограничьте использование гаджетов перед сном: Синий
                      свет от экранов может подавлять выработку мелатонина,
                      гормона, регулирующего сон.
                    </li>
                    <li>
                      ✅ Избегайте стимуляторов: Старайтесь не употреблять
                      кофеин и алкоголь за несколько часов до сна.
                    </li>
                    <li>
                      ✅ Для улучшения качества сна можно использовать продукт
                      LR Night Master. Он способствует расслаблению, ускоряет
                      засыпание и поддерживает глубокий, восстанавливающий сон.
                    </li>
                  </ul>
                  <p class="mt-5">
                    Обеспечение качественного сна — важный шаг к поддержанию
                    здоровья, повышению продуктивности и улучшению общего
                    самочувствия.
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3" id="vitamins">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Заключение: путь к здоровью и энергии начинается сегодня
                  </h2>
                  <p class="mt-3">
                    Ты уже знаешь, какие привычки формируют здоровое тело,
                    энергию и хорошее самочувствие. Осталось только начать
                    действовать.
                  </p>
                  <p class="mt-3">
                    Да, перемены требуют времени и усилий. Но представь, как
                    изменится твоя жизнь, если:
                  </p>
                  <ul class="ul-check">
                    <li>
                      ✅ Просыпаться без чувства усталости, с лёгкостью и
                      бодростью.
                    </li>
                    <li>
                      ✅ Чувствовать себя в форме, смотреть на себя в зеркало с
                      удовольствием.
                    </li>
                    <li>
                      ✅ Иметь стабильную энергию в течение дня, без скачков
                      настроения и слабости.
                    </li>
                    <li>
                      ✅ Наслаждаться едой, которая не только вкусная, но и даёт
                      здоровье.
                    </li>
                  </ul>
                  <p class="mt-3">
                    Все эти изменения – результат работы над собой, шаг за
                    шагом.
                  </p>
                  <p class="mt-3">
                    🔥 Продукция LR – это мощная поддержка на этом пути!
                  </p>
                  <p class="mt-3">
                    ✔ Она закрывает дефициты витаминов и минералов, которые
                    невозможно получить из обычного питания.
                  </p>
                  <p class="mt-3">
                    ✔ Поддерживает обмен веществ, работу ЖКТ,
                    сердечно-сосудистую систему, кожу, волосы и суставы.
                  </p>
                  <p class="mt-3">
                    ✔ Помогает адаптироваться к стрессу, восстанавливаться после
                    нагрузок и лучше спать.
                  </p>
                  <p class="mt-3">
                    💡 Твоё тело – твой главный ресурс. Позаботься о нём – и оно
                    ответит энергией, здоровьем и долголетием.
                  </p>
                  <p class="mt-3">
                    Начни сегодня – и уже через пару недель ты почувствуешь
                    разницу!
                  </p>
                </div>
              </div>

              <div class="text-center mt-10" id="contacts">
                <h3 class="h3-contacts">
                  За подробной консультацией и подбору набора для восполнения
                  дефицитов напишите мне
                </h3>
                <v-icon
                  icon="mdi-chevron-triple-down"
                  size="x-large"
                  color="#94b894"
                  class="mt-5"
                ></v-icon>
                <div class="mt-12 mb-7">
                  <div class="d-flex grow flex-wrap">
                        <div
                            class="contact-avatar v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </div>
              </div>
            </div>
          </v-card>
      </v-responsive>
    </v-container>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Recommendations",
    data: () => ({
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Рекомендации по питанию и образу жизни',
        meta: [
            { vmid: 'description', property: 'description', content: 'Комплексное решение для нормализации иммунитета, повышения энергии, сохранению молодости, нормализации работы ЖКТ' },
            { vmid: 'og:title', property: 'og:title', content: 'Рекомендации по питанию и образу жизни' },
            { vmid: 'og:description', property: 'og:description', content: 'Комплексное решение для нормализации иммунитета, повышения энергии, сохранению молодости, нормализации работы ЖКТ' },
            { vmid: 'og:image', property: 'og:image', content: '' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'Recommendations', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }

        if (this.$cookies.isKey('lifeplus_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('lifeplus_cookie', 'accept', '12m')
        },
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: raleway-b;
  src: url("/fonts/raleway-black.woff2");
  font-weight: 300;
}
.app-fill-height {
  font-family: Roboto,sans-serif;
  font-size: 16px !important;
}
ul li {
  margin-bottom: 5px;
}
.contact-avatar {
  top:-30px;
  bottom:0;
}
.page-box-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 10px;
}
.page-box-content {
  background-color: #fff;
  padding: 10px 5px 20px;
  border-radius: 3px;
}
.h2-green {
  font-family: raleway-b;
  font-size: calc(24px + (36 - 24) * ((100vw - 320px) / (800 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #94b894;
}
.box-border-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 2px;
  border-radius: 5px;
}
.box-border-content {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.img-top-box {
  margin: 20px 0;
}
.eating-marathon {
  max-width: 400px;
  width: 100%;
}
.h2-title-bg {
  text-align: center;
  font-family: raleway-b;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  border-radius: 4px;
}
.h3-green {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.h4-title {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.ul-check {
  list-style-type: none;
  margin-top: 10px;
  padding-left: 30px;
}
.ul-check li {
  position: relative;
  margin-bottom: 15px;  
}
.ul-check li .v-icon {
  color: #94b894;
  position: absolute;
  left: 0;
}
.h3-contacts {
  text-transform: uppercase;
  color: #333333;
}
@media (min-width: 800px) {
  .h2-green {
    font-size: 36px;
  }
}
@media (max-width: 680px) {
}
@media (max-width: 600px) {
}
@media (max-width: 480px) {
}
@media (max-width: 430px) {
}
@media (max-width: 360px) {
}
</style>